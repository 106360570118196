import React from 'react';   
import classnames from 'classnames';

const SwitchSVG = ({type}) => {
    switch (type){
      case 'food': {
        return ( 
            <svg width="64" height="64" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="SurveySparrow-Web" transform="translate(-151.000000, -1453.000000)">
                        <g id="food" transform="translate(151.000000, 1453.000000)">
                            <rect id="Rectangle" strokeOpacity="0.01" stroke="#979797" x="0.5" y="0.5" width="49" height="49"></rect>
                            <g id="fast-food-pizza" transform="translate(11.000000, 7.000000)" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M16.7894737,1.5 C16.7891196,1.29331621 16.8755707,1.09565008 17.0284129,0.953677573 C17.181255,0.811705062 17.3869387,0.73801203 17.5968947,0.75 C20.6287509,0.929394185 23.5253085,2.04609545 25.8725789,3.9405 C26.038553,4.07373377 26.1398764,4.26941057 26.15178,4.47969623 C26.1636836,4.6899819 26.0850705,4.89549614 25.9351579,5.046 L18.0975263,12.915 C17.8804745,13.1325305 17.55109,13.199408 17.2640923,13.0842186 C16.9770946,12.9690292 16.7895188,12.6946645 16.7894737,12.39 L16.7894737,1.5 Z" id="Path" stroke="#4476F0"></path>
                                <path d="M24.2516316,6.7365 C22.2582415,4.81896406 19.5788614,3.7466243 16.7894737,3.74999205" id="Path" stroke="#4476F0"></path>
                                <path d="M24.0058947,12.7815 C23.866152,12.6268361 23.6676196,12.5357537 23.4572417,12.5297897 C23.2468639,12.5238258 23.0433191,12.6035098 22.8947368,12.75 L13.7368421,21.75 L13.7368421,9.021 C13.7371962,8.81431621 13.650745,8.61665008 13.4979029,8.47467757 C13.3450608,8.33270506 13.1393771,8.25901203 12.9294211,8.271 C6.53867995,8.64117925 1.25536645,13.3006734 0.191750668,19.5046891 C-0.871865115,25.7087047 2.56622856,31.812282 8.48073521,34.2199535 C14.3952419,36.6276251 21.2179634,34.7010103 24.9298022,29.5750307 C28.641641,24.4490511 28.2584239,17.4828312 24.0058947,12.78 L24.0058947,12.7815 Z" id="Path" stroke="#2C55B9"></path>
                                <path d="M13.7368421,11.25 C8.63949824,11.2503617 4.25230781,14.7894428 3.25845236,19.7027608 C2.26459692,24.6160788 4.94089965,29.5351043 9.65054852,31.4513884 C14.3601974,33.3676725 19.7913919,31.7474636 22.6224434,27.5816726 C25.4534949,23.4158816 24.8958574,17.8648256 21.2905789,14.3235" id="Path" stroke="#2C55B9"></path>
                                <ellipse id="Oval" stroke="#172239" cx="9.92105263" cy="16.5" rx="1.52631579" ry="1.5"></ellipse>
                                <ellipse id="Oval" stroke="#172239" cx="8.39473684" cy="23.25" rx="1.52631579" ry="1.5"></ellipse>
                                <ellipse id="Oval" stroke="#172239" cx="14.5" cy="27.75" rx="1.52631579" ry="1.5"></ellipse>
                                <ellipse id="Oval" stroke="#172239" cx="19.8421053" cy="23.25" rx="1.52631579" ry="1.5"></ellipse>
                                <path d="M19.4254211,16.161 L19.8421053,16.5 C20.447321,17.1256778 20.4346262,18.1114553 19.8134921,18.7218802 C19.192358,19.3323051 18.1892862,19.344781 17.5526316,18.75 L17.1359474,18.411" id="Path" stroke="#2C55B9"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
      }
      case 'Sales Admin': {
        return ( 
            <svg width="64" height="64" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="SurveySparrow-Web" transform="translate(-219.000000, -1453.000000)">
                        <g id="admin" transform="translate(219.000000, 1453.000000)">
                            <g id="people-man-13" transform="translate(7.000000, 7.000000)" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M35.25,35.25 L35.25,33.75 C35.2487493,31.2473961 33.6943138,29.0084112 31.35,28.1325 L24,25.6635" id="Path" stroke="#4476F0"></path>
                                <path d="M12,25.6635 L4.65,28.1325 C2.30568617,29.0084112 0.751250667,31.2473961 0.75,33.75 L0.75,35.25" id="Path" stroke="#4476F0"></path>
                                <path d="M17.97,24.75 C20.0565,24.75 24.72,22.1685 25.3905,18.2025 C25.4730371,17.7093014 25.7959203,17.2896657 26.2515,17.0835 C27.873,16.3485 28.2795,13.4055 26.97,12.75 C27.72,8.271 27.72,0.75 17.97,0.75 C8.22,0.75 8.22,8.271 8.97,12.75 C7.659,13.4055 8.07,16.35 9.6885,17.0835 C10.1440797,17.2896657 10.4669629,17.7093014 10.5495,18.2025 C11.214,22.1685 15.882,24.75 17.97,24.75 Z" id="Path" stroke="#2C55B9"></path>
                                <path d="M13.5,35.25 L11.172,25.941" id="Path" stroke="#4476F0"></path>
                                <path d="M8.2965,26.9055 L6.336,30.8295 C6.15044574,31.1998079 6.30020324,31.6504235 6.6705,31.836 L8.2395,32.6205 C8.43180568,32.7165696 8.57364391,32.8905384 8.62901503,33.0982519 C8.68438616,33.3059653 8.64796663,33.5274531 8.529,33.7065 L7.5,35.25" id="Path" stroke="#4476F0"></path>
                                <path d="M27.7035,26.9055 L29.664,30.8295 C29.8495543,31.1998079 29.6997968,31.6504235 29.3295,31.836 L27.7605,32.6205 C27.5681943,32.7165696 27.4263561,32.8905384 27.370985,33.0982519 C27.3156138,33.3059653 27.3520334,33.5274531 27.471,33.7065 L28.5,35.25" id="Path" stroke="#4476F0"></path>
                                <path d="M22.5,35.25 L24.828,25.941" id="Path" stroke="#4476F0"></path>
                                <path d="M20.22,27.75 C20.22,28.9926407 19.2126407,30 17.97,30 C16.7273593,30 15.72,28.9926407 15.72,27.75" id="Path" stroke="#172239"></path>
                                <path d="M15.75,35.25 L17.28,29.892" id="Path" stroke="#172239"></path>
                                <path d="M20.25,35.25 L18.714,29.874" id="Path" stroke="#172239"></path>
                                <path d="M8.7435,10.9605 C10.8976865,10.0400322 12.7585367,8.5469943 14.124,6.6435 C16.5186945,9.11112852 19.8114326,10.5025864 23.25,10.5 C24.6112356,10.4987219 25.9634396,10.2789254 27.255,9.849" id="Path" stroke="#2C55B9"></path>
                                <path d="M15.75,19.5 C17.01,20.7 18.99,20.7 20.25,19.5" id="Path" stroke="#4476F0"></path>
                                <path d="M16.5,13.5 C16.1762843,12.992826 15.5999686,12.7046682 15,12.75 C14.4000314,12.7046682 13.8237157,12.992826 13.5,13.5" id="Path" stroke="#4476F0"></path>
                                <path d="M22.5,13.5 C22.1762843,12.992826 21.5999686,12.7046682 21,12.75 C20.4000314,12.7046682 19.8237157,12.992826 19.5,13.5" id="Path" stroke="#4476F0"></path>
                            </g>
                            <rect id="Rectangle-Copy-46" strokeOpacity="0.01" stroke="#979797" x="0.5" y="0.5" width="49" height="49"></rect>
                        </g>
                    </g>
                </g>
            </svg>
          
        )
      }
      case 'hr': {
        return (
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M29 36C29 37.657 30.343 39 32 39C33.657 39 35 37.657 35 36H29Z" fill="url(#paint0_linear)"/>
  <path d="M35 32C34.6022 32 34.2206 32.158 33.9393 32.4393C33.658 32.7206 33.5 33.1022 33.5 33.5C33.5 33.8978 33.658 34.2794 33.9393 34.5607C34.2206 34.842 34.6022 35 35 35C35.3978 35 35.7794 34.842 36.0607 34.5607C36.342 34.2794 36.5 33.8978 36.5 33.5C36.5 33.1022 36.342 32.7206 36.0607 32.4393C35.7794 32.158 35.3978 32 35 32Z" fill="url(#paint1_linear)"/>
  <path d="M29 32C28.6022 32 28.2206 32.158 27.9393 32.4393C27.658 32.7206 27.5 33.1022 27.5 33.5C27.5 33.8978 27.658 34.2794 27.9393 34.5607C28.2206 34.842 28.6022 35 29 35C29.3978 35 29.7794 34.842 30.0607 34.5607C30.342 34.2794 30.5 33.8978 30.5 33.5C30.5 33.1022 30.342 32.7206 30.0607 32.4393C29.7794 32.158 29.3978 32 29 32Z" fill="url(#paint2_linear)"/>
  <path d="M55 19H48V16C48 14.346 46.654 13 45 13H40.816C40.402 11.839 39.302 11 38 11H26C24.698 11 23.598 11.839 23.184 13H19C17.346 13 16 14.346 16 16V19H9C7.346 19 6 20.346 6 22V44C6 45.654 7.346 47 9 47H16V50C16 51.654 17.346 53 19 53H45C46.654 53 48 51.654 48 50V47H55C56.654 47 58 45.654 58 44V22C58 20.346 56.654 19 55 19ZM55 21C55.552 21 56 21.449 56 22V41H54.926C54.626 38.732 53.333 36.615 51.4 35.324C51.77 34.627 52 33.843 52 33V31C52 28.586 50.279 26.566 48 26.101V21H55ZM50 33C50 34.302 49.162 35.402 48 35.816V28.185C49.162 28.599 50 29.699 50 31.001V33ZM50.104 36.888C51.56 37.811 52.569 39.435 52.884 41H48V37.899C48.789 37.738 49.497 37.374 50.104 36.888ZM25 14C25 13.449 25.448 13 26 13H38C38.552 13 39 13.449 39 14V15C39 15.551 38.552 16 38 16H26C25.448 16 25 15.551 25 15V14ZM19 15H23C23 16.654 24.346 18 26 18H38C39.654 18 41 16.654 41 15H45C45.552 15 46 15.449 46 16V47H44.757C44.11 43.562 42.08 40.505 39.152 38.565C39.689 37.49 40 36.282 40 35V31C40 26.589 36.411 23 32 23C27.589 23 24 26.589 24 31V35C24 36.282 24.311 37.49 24.849 38.565C21.921 40.505 19.891 43.561 19.244 47H18V16C18 15.449 18.448 15 19 15ZM38 35C38 38.309 35.309 41 32 41C28.691 41 26 38.309 26 35V31C26 27.691 28.691 25 32 25C35.309 25 38 27.691 38 31V35ZM32 43C34.413 43 36.575 41.921 38.043 40.226C40.421 41.799 42.11 44.237 42.729 47H21.271C21.89 44.237 23.579 41.799 25.958 40.227C27.425 41.922 29.587 43 32 43ZM16 35.816C14.838 35.402 14 34.302 14 33V31C14 29.698 14.838 28.598 16 28.184V35.816ZM13.889 36.883C14.497 37.372 15.208 37.737 16 37.899V41H11.094C11.386 39.298 12.406 37.788 13.889 36.883ZM9 21H16V26.101C13.721 26.566 12 28.585 12 31V33C12 33.842 12.229 34.625 12.599 35.322C10.66 36.605 9.367 38.692 9.073 41H8V22C8 21.449 8.448 21 9 21ZM9 45C8.448 45 8 44.551 8 44V43H16V45H9ZM45 51H19C18.448 51 18 50.551 18 50V49H46V50C46 50.551 45.552 51 45 51ZM55 45H48V43H56V44C56 44.551 55.552 45 55 45Z" fill="#8D5AAD"/>
  <defs>
  <linearGradient id="paint0_linear" x1="32" y1="36.125" x2="32" y2="39.188" gradientUnits="userSpaceOnUse">
  <stop stopColor="#6DC7FF"/>
  <stop offset="1" stopColor="#E6ABFF"/>
  </linearGradient>
  <linearGradient id="paint1_linear" x1="35" y1="31.938" x2="35" y2="34.938" gradientUnits="userSpaceOnUse">
  <stop stopColor="#6DC7FF"/>
  <stop offset="1" stopColor="#E6ABFF"/>
  </linearGradient>
  <linearGradient id="paint2_linear" x1="29" y1="31.938" x2="29" y2="34.938" gradientUnits="userSpaceOnUse">
  <stop stopColor="#6DC7FF"/>
  <stop offset="1" stopColor="#E6ABFF"/>
  </linearGradient>
  </defs>
  </svg>
  
          
        )
      }
      case 'Customer Support': {
        return (  
        <svg width="64" height="64" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="SurveySparrow-Web" transform="translate(-357.000000, -1453.000000)">
                    <g id="device" transform="translate(357.000000, 1453.000000)">
                        <g id="desktop-computer-pc" transform="translate(7.000000, 10.000000)" strokeLinecap="round" strokeLinejoin="round">
                            <rect id="Rectangle" stroke="#2C55B9" x="0.75" y="8.25" width="21" height="16.5" rx="1.5"></rect>
                            <path d="M11.25,24.75 L11.25,29.25" id="Path" stroke="#2C55B9"></path>
                            <path d="M6.744,29.25 L15.744,29.25" id="Path" stroke="#2C55B9"></path>
                            <path d="M35.25,6.75 L24.75,6.75" id="Path" stroke="#4476F0"></path>
                            <path d="M35.25,11.25 L24.75,11.25" id="Path" stroke="#4476F0"></path>
                            <path d="M20.25,5.25 L20.25,2.25 C20.25,1.42157288 20.9215729,0.75 21.75,0.75 L33.75,0.75 C34.5784271,0.75 35.25,1.42157288 35.25,2.25 L35.25,27.75 C35.25,28.5784271 34.5784271,29.25 33.75,29.25 L21.75,29.25 C20.9215729,29.25 20.25,28.5784271 20.25,27.75" id="Path" stroke="#2C55B9"></path>
                            <circle id="Oval" stroke="#172239" cx="27.75" cy="21" r="2.25"></circle>
                        </g>
                        <rect id="Rectangle-Copy-48" strokeOpacity="0.01" stroke="#979797" x="0.5" y="0.5" width="49" height="49"></rect>
                    </g>
                </g>
            </g>
        </svg>
        )
      }
      case 'others': {
        return ( 
            <svg width="64" height="64" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="SurveySparrow-Web" transform="translate(-288.000000, -1453.000000)">
                        <g id="others" transform="translate(288.000000, 1453.000000)">
                            <g id="task-list-add" transform="translate(7.000000, 7.000000)" strokeLinecap="round" strokeLinejoin="round">
                                <polyline id="Path" stroke="#4476F0" points="21.774 14.211 21.774 8.211 5.274 8.211 5.274 27.7095 12.774 27.711"></polyline>
                                <path d="M26.274,12.711 L26.274,5.211 C26.274,4.38257288 25.6024271,3.711 24.774,3.711 L18.102,3.711 C17.3069046,1.89322273 15.5110592,0.718604008 13.527,0.718604008 C11.5429408,0.718604008 9.74709538,1.89322273 8.952,3.711 L2.274,3.711 C1.44557288,3.711 0.774,4.38257288 0.774,5.211 L0.774,30.711 C0.774,31.5394271 1.44557288,32.211 2.274,32.211 L14.274,32.211" id="Path" stroke="#2C55B9"></path>
                                <circle id="Oval" stroke="#172239" cx="26.274" cy="26.211" r="9"></circle>
                                <path d="M26.274,21.711 L26.274,30.711" id="Path" stroke="#4476F0"></path>
                                <path d="M30.774,26.211 L21.774,26.211" id="Path" stroke="#4476F0"></path>
                                <path d="M9.774,12.711 L17.274,12.711" id="Path" stroke="#4476F0"></path>
                                <path d="M9.774,17.211 L17.274,17.211" id="Path" stroke="#4476F0"></path>
                                <path d="M9.774,21.711 L14.274,21.711" id="Path" stroke="#4476F0"></path>
                            </g>
                            <rect id="Rectangle-Copy-47" strokeOpacity="0.01" stroke="#979797" x="0.5" y="0.5" width="49" height="49"></rect>
                        </g>
                    </g>
                </g>
            </svg>
  
        )
      }
      default: return null
    }
  }
  export default function CategoryItem ({name,label,type,active, onClick}) {
    return (<div
      onClick={onClick}
      className={classnames({
      "surveyTypeItem--container":true,
      "active":active,
    })}> 
        <SwitchSVG type={name} /> 
        <h4 size="h3" weight="semibold" color="black" extraClass={'mt--sm'}>{label}</h4>
    </div>
    )
  }
