const portal_data = [

{
    category: 'Customer Support',
    category_icon:'',
    survey_list: [
        {
            category: 'Customer Support',
            name: 'Refund Request Form',
            desc: 'For approving customer refund request',
            url:'https://sprw.io/stt-a9273b'
        },
        {
            category: 'Customer Support',
            name: 'Offline Invoice Form',
            desc: 'For generating invoice for offline devices',
            url:'https://sprw.io/stt-1cee38'
        }, 
        {
            category: 'Customer Support',
            name: 'Discount Form',
            desc: 'For approving discount requests of more than 30%',
            url:'https://sprw.io/stt-e0c7db'
        }, 
        {
            category: 'Customer Support',
            name: 'Purchase Order Form',
            desc: 'For generating purchase order for customers',
            url:'https://sprw.io/stt-437f2e'
        }, 
    ]
},
{
    category: 'Sales Admin',
    survey_list: [ 
        {
            category: 'Sales Admin',
            name: 'Lead Reassignment Form',
            desc: 'For reassigning the contact owner of leads',
            url:' https://sprw.io/stt-1ed011'
        }, 
    ]
}, 
];

export default portal_data